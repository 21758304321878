@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;

  min-height: 100vh;
  background-color: #1C232B;
  font-family: "Playfair Display", 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}