.ambient-sound {
  opacity: 0.5;
  border: 3px solid white;
  border-radius: 15px;
  padding: 1rem;
  transition: opacity 0.5s;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: transparent;
  transition: background-color 0.5s;
}

.ambient-sound:hover {
  opacity: 1;
}

.ambient-sound button {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.5s;
  font-size: 1.5rem;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;

  letter-spacing: 0.2rem;
}

.active {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.hidden {
  display: none;
}

.random-control {
  display: flex;
  margin-top: auto;
}

.random-control button {
  font-size: 0.8rem;
  border: 2px solid white;
  flex-grow: 1;
}

.random-control button:hover, .random-control button.random-active {
  background-color: white;
  color: #1C232B;
}

.random-control button:first-child {
  border-radius: 15px 0 0 15px;
  border-right: none;
}

.random-control button:last-child {
  border-radius: 0 15px 15px 0;
}