body {
background-image: url("../public/images/sea.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 90%;
  background-attachment: fixed;


}

header {
    font-family: "Playfair Display", serif;
  color:white;
  font-weight: lighter;

  padding-top: 200px;
  padding-bottom: 200px;

  text-align: center;
}

header h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  letter-spacing: 1rem;;
}

header h1, header h2 {
  opacity: 0.9;
  transition: opacity 0.5s;
}

header h1:hover, header h2:hover {
  opacity: 0.3;
}

header h2 {
  letter-spacing: 0.38rem;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.control-container {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  max-width: 60rem;
}

.control-container button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border: 0;
  background-color: transparent;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.control-container button:hover {
  transform: scale(1.1);
}

.category-title {
  color: rgba(255, 255, 255, 0.795);
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.3rem;
  font-family: "Playfair Display", serif;
  font-size: 2rem;
}

.sound-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 5rem;
  max-width: 60rem;
  padding: 1rem;
}

.search-container {
  display: flex;
  width: 40rem;
}

.search-container input {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.search-container button {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid white;
}

@media (max-width: 1000px) {
  .sound-container {
    grid-template-columns: 1fr 1fr;
  }

  .search-container {
    width: 80%;
  }
}

@media (max-width: 680px) {

  header {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  header h1 {
    font-size: 2rem;
  }

  .control-container {
    gap: 2rem;
  }

  .control-container button {
    font-size: 1rem;
  }

  .search-container input {
    font-size: 1rem;
  }

  .category-title {
    font-size: 1.5rem;
  }

  .sound-container {
    grid-template-columns: 1fr;
  }
}

#filter-input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid white;
  min-width: 20rem;
  margin: 0 auto;
  color: white;
  padding: 1rem;
  font-size: 2rem;
}

footer {
  margin: 2rem 0;
  text-align: center;
  font-size: 1rem;
  color: white;
}